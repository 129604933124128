// Font Mixins
@mixin serif-font {
  font-family: "Droid Serif", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
@mixin script-font {
  font-family: "Kaushan Script", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
@mixin body-font {
  font-family: /*'Roboto Slab',*/ -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
@mixin heading-font {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@mixin box-shadow-sm {
  box-shadow: 4px 4px 5px -4px rgba(0, 0, 0, .5);
}

@mixin box-shadow-lg {
  box-shadow: 3px 3px 4px -2px rgba(0, 0, 0, .6);
}

@mixin box-shadow-bold {
  box-shadow: 5px 5px 0px 0px $primary;
  &:hover {
    box-shadow: 0px 0px 0px 5px $primary;
  }
}

// Styling for the timeline section
.timeline {
  // background: url("#{$timeline-image}");
  position: relative;
  padding: 0;
  list-style: none;

  .year {
    font-size: .9em;
  }

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    content: "";
    background-color: $gray-200;
  }

  >li {
    position: relative;
    min-height: 50px;
    margin-bottom: 50px;

    &:after,
    &:before {
      display: table;
      content: " ";
    }

    &:after {
      clear: both;
    }

    .timeline-panel {
      position: relative;
      float: right;
      width: 100%;
      padding: 280px 20px 0 100px;
      text-align: left;

      &:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
      }

      &:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
      }
    }

    .timeline-image {
      position: absolute;
      z-index: 90;
      left: 0;
      width: 312px;
      // height: 183px;
      height: 212px;
      margin-left: 0px;
      text-align: center;
      color: $white;
      border: 7px solid $lightgray;
      // border-radius: 100%;
      // background-color: $primary;
      @include box-shadow-lg;

      h4 {
        font-size: 10px;
        line-height: 14px;
        margin-top: 12px;
      }
    }

    &.timeline-inverted>.timeline-panel {
      float: right;
      padding: 280px 20px 0 100px;
      text-align: left;

      &:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
      }

      &:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.timeline-inverted>.timeline-image:last-child {
      width: 150px;
      height: 150px;
      margin-left: 0px;
      margin-top: 0px;
      background-color: $primary;
      border-radius: 100%;

      h4 {
        font-size: 15px;
        line-height: 28px;
        margin-top: 24px;
      }
    }
  }

  .timeline-heading {
    h4 {
      margin-top: 0;
      color: inherit;

      &.subheading {
        text-transform: none;
      }
    }
  }

  .timeline-body {

    >ul,
    >p {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 768px) {
  .timeline {
    &:before {
      left: 50%;
    }

    >li {
      min-height: 100px;
      margin-bottom: 100px;

      .timeline-panel {
        float: left;
        width: 41%;
        padding: 0 20px 20px 30px;
        text-align: right;
      }

      .timeline-image {
        left: 50%;
        width: 386px;
        // height: 227px;
        height: 262px;
        margin-left: -50px;

        h4 {
          font-size: 13px;
          line-height: 18px;
          margin-top: 16px;
        }
      }

      &.timeline-inverted>.timeline-panel {
        float: right;
        padding: 0 30px 20px 20px;
        text-align: left;
      }

      &.timeline-inverted>.timeline-image {
        margin-left: -343px;
      }

      &.timeline-inverted>.timeline-image:last-child {
        width: 150px;
        height: 150px;
        margin-left: -75px;
        margin-top: 0px;
        background-color: $primary;
        border-radius: 100%;

        h4 {
          font-size: 18px;
          line-height: 28px;
          margin-top: 24px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .timeline {
    >li {
      min-height: 150px;

      .timeline-panel {
        padding: 0 20px 20px;
      }

      .timeline-image {
        width: 445px;
        // height: 260px;
        height: 300px;
        margin-left: -37px;
      }

      &.timeline-inverted>.timeline-panel {
        padding: 0 20px 20px;
      }

      &.timeline-inverted>.timeline-image {
        margin-left: -411px;
      }

      &.timeline-inverted>.timeline-image:last-child {
        width: 150px;
        height: 150px;
        margin-left: -75px;
        margin-top: 0px;
        background-color: $primary;
        border-radius: 100%;

        h4 {
          font-size: 20px;
          line-height: 30px;
          margin-top: 21px;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .timeline {
    >li {
      min-height: 350px;

      &:last-child {
        min-height: 150px;
      }

      .timeline-panel {
        padding: 0 20px 20px 100px;
      }

      .timeline-image {
        width: 500px;
        // height: 293px;
        height: 337px;
        margin-left: -24px;
      }

      &.timeline-inverted>.timeline-panel {
        padding: 0 100px 20px 20px;
      }

      &.timeline-inverted>.timeline-image {
        margin-left: -480px;
      }

      &.timeline-inverted>.timeline-image:last-child {
        width: 200px;
        height: 200px;
        margin-left: -100px;
        margin-top: 0px;
        background-color: $primary;
        border-radius: 100%;

        h4 {
          font-size: 24px;
          margin-top: 31px;
          line-height: 39px;
        }
      }
    }
  }
}
// Styling for the masthead
header.masthead {
  text-align: center;
  color: white;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("#{$header-image}");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
  
    .intro-text {
      padding-top: 150px;
      padding-bottom: 100px;
  
      .intro-lead-in {
        font-size: 22px;
        font-style: italic;
        line-height: 22px;
        margin-bottom: 25px;
        @include serif-font;
      }
  
      .intro-heading {
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        margin-bottom: 25px;
        @include script-font;
      }
    }
  
    .btn {
      font-size: 12px;
      padding: 16px 30px;
    }
    }
  
    @media (min-width: 768px) {
      header.masthead {
        .intro-text {
          padding-top: 250px;
          padding-bottom: 200px;
  
          .intro-lead-in {
            font-size: 40px;
            font-style: italic;
            line-height: 40px;
            margin-bottom: 25px;
            @include serif-font;
          }
  
          .intro-heading {
            font-size: 75px;
            font-weight: 700;
            line-height: 75px;
            margin-bottom: 50px;
            @include script-font;
          }
        }
  
        .btn {
          font-size: 20px;
          padding: 20px 40px;
        }
      }
    }
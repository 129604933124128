// Styling for the contact section
section#contact {
  background-color: $gray-900;
  // background-image: url("#{$contact-image}");
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)), url("#{$contact-image}");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  border-radius: 0rem 0rem 2rem 0rem;
  .section-heading {
    color: $secondary;
  }
  .form-group {
    margin-bottom: 25px;
    input,
    textarea {
      padding: 20px;
    }
    input.form-control {
      height: auto;
    }
    textarea.form-control {
      height: 248px;
    }
  }
  .form-control:focus {
    border-color: $primary;
    box-shadow: none;
  }
  ::-webkit-input-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  :-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  ::-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  :-ms-input-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 350ms ease-in;
}

.fade-in.appear {
  opacity: 1;
}

.from-left {
  grid-column: 2 / 3;
  transform: translateX(-50%);
}

.from-right {
  grid-column: 3 / 4;
  transform: translateX(50%);
}

.from-left,
.from-right {
  transition: opacity 250ms ease-in, transform 400ms ease-in;
  opacity: 0;
}

.from-left.appear,
.from-right.appear {
  transform: translateX(0);
  opacity: 1;
}

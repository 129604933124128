.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.55);
  // &:hover {
  //   background: rgba(0, 0, 0, 0.45);
  // }
  color: white;
  // margin-bottom: 100px;
  position: relative;
  overflow: hidden;
  min-height: 50vh;

  .content {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    max-width: 960;

    .time {
      padding: 1.5rem;
    }
  }

  .video-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    min-height: 100%;
    z-index: -1;
    object-fit: cover;
  }

}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
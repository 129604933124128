// Styling for the navbar
#mainNav {
  background-color: white;
  .navbar-toggler {
    font-size: 12px;
    right: 0;
    padding: 13px;
    text-transform: uppercase;
    color: white;
    border: 0;
    background-color: $primary;
    @include heading-font;
  }
  .navbar-brand {
    color: $primary;
    @include script-font;
    &.active,
    &:active,
    &:focus,
    &:hover {
      color: darken($primary, 10%);
    }
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        text-align: center;
        font-size: 90%;
        font-weight: 400;
        padding: 0.75em 0;
        letter-spacing: 1px;
        color: $secondary;
        @include heading-font;
        &:hover {
          color: darken($secondary, 10%);
        }
      }
    }
  }
}

// Desktop styles
@media (min-width: 992px) {
  #mainNav {
    padding-top: 70px;
    padding-bottom: 25px;
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    border: none;
    background-color: white;
    color: $secondary;
    z-index: 99;
    @include box-shadow-lg;
    .navbar-brand {
      font-size: 1.75em;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: 1.1em 1em !important;
          color: $secondary;
          border-bottom: 3px solid transparent;
          &:hover {
            color: darken($secondary, 10%);
            border-bottom: 3px solid $primary;
          }
        }
      }
    }
    &.navbar-shrink {
      padding-top: 0;
      padding-bottom: 0;
      background-color: white;
      box-shadow: 0px 21px 21px -25px grey;
      .navbar-brand {
        font-size: 1.25em;
        padding: 12px 0;
      }
    }
  }
}


.topbar {
  display: none;
}

@media (min-width: 992px) {
  .topbar {
    display: block;
    margin: 0px 0px;
    color: $secondary;
    background-color: white;
    z-index: 999;
    position: relative;
    @include box-shadow-sm;
    .container {
      .row {
        // float: left;
        // background-color: #000;
        .info {
          background: none;
          width: 100%;
          z-index:9999;
          span {
            margin-top: 10px;
            float: left;
            margin-right: 20px;
          }
          .quote {
            float: right;
            background-color: $secondary;
            color: #fff;
            padding: 10px 20px;
            margin-top: 0px;
            box-shadow: 0px 2px 0px 2px $secondary;
            border-radius: 0% 0% 2% 2%;
            // @include box-shadow-sm;
            &:hover {
              box-shadow: 0px 3px 0px 3px darken($secondary, 2%);
              background-color: darken($secondary, 2%);
            }
            a {
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

// Global styling for this template
body {
  overflow-x: hidden;
  @include body-font;
}

* {
  // outline: 1px solid red;
}

p {
  line-height: 1.75;
}

a {
  color: $primary;
  &:hover {
    color: darken($primary, 10%);
  }
}

.text-primary {
  color: $primary !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  @include heading-font;
}

hr {
  width: 50%;
  max-width: 300px;
  border-top: 5px solid $primary;
  border-radius: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.page-section {
  padding: 100px 0;
  overflow-x: hidden;
  h2.section-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 15px;
    color: $secondary;
  }
  h3.section-subheading {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 75px;
    text-transform: none;
    @include serif-font;
  }
  .text-shadow {
    text-shadow: 2px 2px 3px black;
  }
}
@media (min-width: 768px) {
  section {
    padding: 150px 0;
  }
}

// Highlight color customization
::-moz-selection {
  // background: $primary;
  background: #dedede;
  text-shadow: none;
}

::selection {
  // background: $primary;
  background: #dedede;
  text-shadow: none;
}

img {
  max-width: 100%;
}
img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

summary {
  font-size: 20px;
  font-weight: bold;
  border-top: 2px solid $secondary;
  color: $secondary;
  margin: 4px 0px;
  padding: 9px;
  border-radius: 10px;
  box-shadow: 0px -10px 14px -15px #000000ba;
  &:hover {
    border-top: 2px solid $primary;
    background: $primary;
    color: white;
  }
}

.calltoaction {
  margin-top: 0px;
  // margin-bottom: 70px;
  padding: 85px 0px;
  background: $secondary;
  h2 {
    color: white;
  }

  p {
    color: white;
  }
}

.slick-slider {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.owl-carousel {
  .owl-item {
    img {
      display: block;
      width: inherit;
    }

    .single-clients {
      img {
        display: inline-block;
        width: inherit;
      }
    }
  }
}

// Styling for the team section
.testimonial {
  margin-bottom: 50px;
  padding: 0px 30px;
  text-align: center;
.rating {
    margin-top: 1.5rem;
    color: $primary;
  }
  .review {
    font-size: 1.1em;
  }
}

.inset {
  box-shadow: inset 0px 0px 24px -5px $highlight;
}

.gallery {
  padding-bottom: 0px;
}

.iframe-container {
  .videobackgroundtext {
    height: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 97;
    h1 {
      color: $secondary;
    }
  }
}

.logo {
  img {
    // max-width: 700px;
  }
}

.owl-item {
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}

.bg-light-gray {
  background: $lightgray;
  background-image: linear-gradient(white, $lightgray);
}

.bg-primary-color {
  background-image: linear-gradient(white, $primary);
  color: white;
}

input[type="submit" i] {
  color: blue;
}

.delay1 {
  animation-delay: 750ms;
}

.delay2 {
  animation-delay: 1.5s;
}
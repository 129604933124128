// Styling for the pricing section
.card {
    box-shadow: 0px 5px 10px -5px grey;
    border: 7px solid $lightgray;
    &:hover {
        box-shadow: 0px 6px 9px -4px grey;
    }
}

.primary {
    box-shadow: 0px 7px 12px -3px grey;
    &:hover {
            box-shadow: 0px 8px 11px -2px grey;
        }
}
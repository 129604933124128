// Styling for the team section
.team-member {
  margin-bottom: 50px;
  text-align: center;
  img {
    width: 225px;
    height: 225px;
    border: 7px solid fade-out($black, 0.9);
  }
  h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
  }
  p {
    margin-top: 0;
  }
  .fa-star {
    color: $primary;
    font-size: 24px;
  }
}

@charset "utf-8";
// Core variables and mixins
@import "base/variables.scss";
@import "base/mixins.scss";

// Custom colors
$primary: #06B0E4 !default;
$secondary: #373735 !default;
$highlight: #373735 !default;
$white: #FFF !default;
$black: #000 !default;
$lightgray: #e2e2e2 !default;
$lightgray2: #f8f9fa !default;
$primary-transparent: rgba(86, 24, 40, .8) !default;

// Custom Images
$header-image: "../img/header/airtour-view1.jpg";
$contact-image: "../img/airtour.jpg";

// Global CSS
@import "base/page.scss";

// Components
@import "components/buttons.scss";
@import "components/navbar.scss";
@import "components/fade-in.scss";
@import "components/client-scroll.scss";

// Layout
@import "layout/masthead.scss";
@import "layout/services.scss";
@import "layout/portfolio.scss";
@import "layout/timeline.scss";
@import "layout/videobackground.scss";
@import "layout/team.scss";
@import "layout/pricing.scss";
@import "layout/contact.scss";
@import "layout/footer.scss";
